"use client";

import {
  CORE_UNAVAILABLE_ERR_MSG,
  ENV_CONFIG_MESSAGES,
} from "@/components/data/error-data";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import {
  AppDispatch,
  getAppLoadingState,
  getEnvId,
  getOrgID,
  setAllowConfigChangeState,
  setEntitlement,
} from "@/store";
import { useToast } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuProvider, useUserAuthenticated } from "./providers";
import {
  fetchEnvironmentConfig,
  fetchSystemVersion,
  getAllowConfigChangesState,
  getEnvType,
  loadEnvOrProj,
} from "@/store/slices/environment";
import { fetchProject } from "@/store/slices/project";
import { fetchAppConfig, getAppConfig } from "@/store/slices/app";
import { Loader } from "@/components/common";
import {
  fetchAllOrgs,
  fetchConsolePermissions,
  fetchCorePermissions,
  fetchEnvStatus,
  getCorePermissions,
  getEnvStatus,
} from "@/store/slices/organization";
import { PrismicPreview } from "@prismicio/next";
import { isConsolePages, useUserEntitlement } from "@/utils/common";
import { EnvAllowConfigChanges } from "@/utils/constants";

const Children = ({ children }: { children: React.ReactNode }) => {
  const config = useSelector(getAppConfig);
  const [key, setKey] = useState(1);
  const envId = useSelector(getEnvId);
  const envType = useSelector(getEnvType);
  const pathName = usePathname();
  const toast = useToast();
  const orgId = useSelector(getOrgID);
  const entitlement = useUserEntitlement();
  const corePermission = useSelector(getCorePermissions);
  const isLoading = useSelector(getAppLoadingState);
  const allowConfigChanges = useSelector(getAllowConfigChangesState);
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useUserAuthenticated();
  const envStatus = useSelector(getEnvStatus);
  const toastId = "config-warning-toast";
  const coreUnavailableToastId = "core-unavailable-toast";

  const showToast =
    allowConfigChanges === EnvAllowConfigChanges.no ||
    allowConfigChanges === EnvAllowConfigChanges["with-warning"];

  //check the env status version
  const isEnvDown = useMemo(() => {
    const currectEnv = envStatus.filter((data: any) => data.id === envId);
    //If version is null means env is down
    return currectEnv.some((data: any) => data.version === null);
  }, [envStatus, envId]);

  // Remount the children when environment/org changes
  useEffect(() => {
    if (!isLoading) {
      setKey((oldKey) => oldKey + 1);
    }
  }, [isLoading]);

  useEffect(() => {
    const isConsole = isConsolePages();
    // No toast if env is not selected
    if (!toast.isActive(coreUnavailableToastId) && (!envId || !isConsole)) {
      toast.closeAll();
    }
    if (showToast && envId && isConsole && !toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: ENV_CONFIG_MESSAGES[allowConfigChanges],
        status: "error",
        duration: null,
      });
    }
  }, [envId, pathName, toast, showToast, allowConfigChanges]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (isEnvDown && !toast.isActive(coreUnavailableToastId)) {
        toast({
          id: coreUnavailableToastId,
          description: CORE_UNAVAILABLE_ERR_MSG,
          status: "error",
          duration: null,
        });
      } else if (!isEnvDown) {
        toast.close(coreUnavailableToastId);
      }
    }, 100);

    return () => clearTimeout(timeOut);
  }, [isEnvDown, pathName, envId]);

  //Toggle the visibility of qualtrics sticky feedback button based on pathname
  useEffect(() => {
    const button = document.getElementsByClassName("QSIFeedbackButton");
    if (button.length > 0) {
      if (
        pathName?.endsWith(`${ROUTE_BASE_URL.ACADEMY}/`) ||
        pathName?.endsWith(`${ROUTE_BASE_URL.ACADEMY}`) ||
        pathName?.includes(`${ROUTE_BASE_URL.API_LIBRARY}`) ||
        pathName?.includes(`${ROUTE_BASE_URL.DOCUMENT_CENTER}`)
      ) {
        button[0].setAttribute("style", "display: none");
      } else {
        button[0].setAttribute("style", "display: flex");
      }
    }
  }, [pathName]);

  useEffect(() => {
    if (corePermission.length > 0) {
      dispatch(setEntitlement(entitlement));
    }
  }, [corePermission]);

  // Load app state, user's orgs
  useEffect(() => {
    dispatch(fetchAppConfig());
    if (isLoggedIn) {
      dispatch(loadEnvOrProj());
      dispatch(fetchAllOrgs());
    }
  }, [isLoggedIn, dispatch]);

  // Fetch permissions, env config, env status
  useEffect(() => {
    if (isLoggedIn && orgId != null) {
      dispatch(fetchConsolePermissions({ envId, envType }));
      dispatch(fetchEnvStatus());
      if (envId && envType) {
        dispatch(fetchSystemVersion());
        // Projects are not backed by CaC, allow edits
        if (envType === "proj") {
          dispatch(setAllowConfigChangeState(EnvAllowConfigChanges.yes));
          dispatch(fetchProject({ envId, envType }));
        } else {
          dispatch(fetchEnvironmentConfig({ envId, envType }));
        }
        dispatch(fetchCorePermissions());
      }
    }
  }, [orgId, envId, envType, dispatch, isLoggedIn]);

  return (
    <Fragment key={key}>
      {isLoading ? (
        <Loader isOverlay />
      ) : (
        <MenuProvider>
          {children}
          <PrismicPreview repositoryName={config?.prismicRepo ?? ""} />
        </MenuProvider>
      )}
    </Fragment>
  );
};

export default Children;
